
.title {
    font-weight: bold;
    min-height:60px;
    border-bottom:1px solid rgb(232, 232, 232);
    width:100%;
    box-sizing: border-box;
    padding:10px;
    padding-bottom:0px;
  }

.top-half {
  display: flex;
  flex-wrap: wrap;
}

.left-panel {
  width:520px;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.right-panel {
  flex: 1;
  min-width:520px;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.MAIN_WALLET{
    width:100%;
    height:620px;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.side-wallet-details{
    width:145px;
    height:100%;
    box-sizing: border-box;
    border-bottom: 1px solid #e8e8e8;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow:hidden;
}
.side-wallet-details .current-amounts{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:100%;
    margin-top:5px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 20px 10px rgba(0, 0, 0, 0.5); /* Add box shadow */
}
.side-wallet-details .current-amounts .current-inner-body-wrap{
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-image: linear-gradient(to bottom, #5e828f, #2e4e5d); */
    /* background-image: linear-gradient(to bottom, #80b2c6, #2b7fa0); */
    background:white;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-top:1px solid rgb(208, 208, 208);
    border-right:1px solid black;
    border-left:1px solid black;
    border-bottom:2px solid black;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-bottom:10px;
    box-sizing: border-box;
}

.side-wallet-details .transactions-wrap{
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius:10px;
    height:345px;
    border:1px solid black;
    z-index:4;
    background:white;
    border-top:1px solid white;
    /* background-image: linear-gradient(to bottom, #80b2c6, #2b7fa0); */
    box-sizing: border-box;
}
.side-wallet-details .transactions-wrap .available{
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius:10px;
    margin-bottom:10px;
    z-index:4;
}
.side-wallet-details .transactions-wrap .pending{
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius:10px;
    margin-bottom:10px;
}


.side-wallet-details .spacer{
    height:2px;
    width:80%;
    background:rgb(203, 203, 203);
    margin-top:4px;
    margin-bottom:4px;
    border-radius:2px;
}

.side-wallet-details .wallet-settings{
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position:relative;
    z-index:10;
    /* background:#e6f3f3; */
    /* background:#e5e9ea; */
    color: #55a37f;
    font-weight:800;
    cursor:pointer;
    transition: .4s ease;
}
.side-wallet-details .wallet-settings:hover{
    box-shadow:inset 1px 1px 10px #333;
}
.side-wallet-details .wallet-graphic{
    height:120px;
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border:1px solid black;
    border-radius:4px;
    font-family: Cabolafe, Arial, serif;
    font-size:20px;
    transition: .4s ease;
    letter-spacing:1px;
}
.side-wallet-details .wallet-graphic:hover{
    font-size:22px !important;
}
.wallet-overview{
    flex:1;
    height:100%;
    box-sizing: border-box;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.graphical-display {
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(255, 255, 255);
}
.graphical-display .address-row{
    width:100%;
    height:calc(100% / 6);
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.graphical-display .address-wrap{
    width:77px;
    height:77px;
    box-sizing: border-box;
    border:1px solid rgb(0, 0, 0);
    /* background:#f2f2fb; */
    background: #fcfffb;
    border-radius:2px;
    cursor:pointer;
    box-shadow: 1px 1px 2px rgba(221, 255, 233, 0.4),
                -1px 1px 2px rgba(221, 255, 233, 0.4),
                1px -1px 2px rgba(221, 255, 233, 0.4),
                -1px -1px 2px rgba(221, 255, 233, 0.4);
    position:relative;
    transition:.2s;
}
.graphical-display .address-wrap.in-use{
    width:77px;
    height:77px;
    box-sizing: border-box;
    border:1px solid rgb(0, 0, 0);
    background:#99bfa4;
    border-radius:2px;
    cursor:pointer;
    box-shadow: 1px 1px 2px rgba(221, 255, 233, 0.4),
                -1px 1px 2px rgba(221, 255, 233, 0.4),
                1px -1px 2px rgba(221, 255, 233, 0.4),
                -1px -1px 2px rgba(221, 255, 233, 0.4);
}
.graphical-display .address-wrap:hover{
    /* background:#ffe7cb; */
    background:#dcdce1;
}
.graphical-display .address-wrap.in-use:hover{
    background:rgb(115, 213, 134);
}

.graphical-display .address-wrap.locked{
    background:#afafb1;
    transition:0s !important;
}

.graphical-display .address-wrap .unlock-x{
    width: 25px;
    height: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

:root {
    --delay-offset: .05
}
#tier0_5{
    animation:.5s addressAppear forwards ease;
    animation-delay: calc(1 * var(--delay-offset) * 1s);
}

#tier0_4,
#tier0_9,
#tier0_10{
    animation:.5s addressAppear forwards ease;
    animation-delay: calc(2 * var(--delay-offset) * 1s);
}

#tier0_3,
#tier0_8,
#tier0_13,
#tier0_14,
#tier0_15{
    animation:.5s addressAppear forwards ease;
    animation-delay: calc(3 * var(--delay-offset) * 1s);
}

#tier0_2,
#tier0_7,
#tier0_12,
#tier0_17,
#tier0_18,
#tier0_19,
#tier0_20{
    animation:.5s addressAppear forwards ease;
    animation-delay: calc(4 * var(--delay-offset) * 1s);
}

#tier0_1,
#tier0_6,
#tier0_11,
#tier0_16,
#tier0_21,
#tier0_22,
#tier0_23,
#tier0_24,
#tier0_25{
    animation:.5s addressAppear forwards ease;
    animation-delay: calc(5 * var(--delay-offset) * 1s);
}

#tier0_26,
#tier0_27,
#tier0_28,
#tier0_29,
#tier0_30 {
    animation:.5s addressAppear forwards ease;
    animation-delay: calc(6 * var(--delay-offset) * 1s);
}


#tier0_1,
#tier0_2,
#tier0_3,
#tier0_4,
#tier0_5,
#tier0_6,
#tier0_7,
#tier0_8,
#tier0_9,
#tier0_10,
#tier0_11,
#tier0_12,
#tier0_13,
#tier0_14,
#tier0_15,
#tier0_16,
#tier0_17,
#tier0_18,
#tier0_19,
#tier0_20,
#tier0_21,
#tier0_22,
#tier0_23,
#tier0_24,
#tier0_25,
#tier0_26,
#tier0_27,
#tier0_28,
#tier0_29,
#tier0_30 {
  opacity:0;
  position:relative;
  transform:translate(-15px, -15px) rotate(5deg);
}



@keyframes addressAppear {
    0% {opacity:0;transform:translate(-15px, -15px) rotate(5deg);}
    100% {opacity:1;transform: translate(0px, 0px) rotate(0deg);}
}


.stats {
    width: 100%;
    height: 140px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* border-bottom: 1px solid rgb(232, 232, 232);
    border-right: 1px solid rgb(232, 232, 232); */
}


.stats .overall-left{
    width: 124px;
    box-sizing: border-box;
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.stats .overall-left .top{
    width:100%;
    flex:2;
    padding: 3px;
    box-sizing: border-box;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    background: #f7f7f7;
    position:relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
}
.stats .overall-left .top .network-fee-graphic{
    position: absolute;
    width: 60px;
    height: 60px;
    left:0;
    top:0;
}
.stats .overall-left .top .network-fee-graphic svg{
    transform:rotate(-90deg);
}
.stats .overall-left .top .network-fee-graphic .background-ring{
    stroke:#dadada;
    stroke-width:10px;
    fill:transparent;
    stroke-linecap:round;
    stroke-dasharray: 113;
    stroke-dashoffset: -30;
}
.stats .overall-left .top .network-fee-graphic .indicator-ring{
    stroke:#dadada;
    stroke-width:10px;
    fill:transparent;
    stroke-linecap:round;
    stroke-dasharray: 113;
    stroke-dashoffset: -30;
}
/* .stats .overall-left .top .network-fee-graphic::before{
    content: '';
    position: absolute;
    top: -50%;
    left: 0;
    width: 100%;
    height: 200%;
    background-color: transparent;
    border: 10px solid red;
    border-radius: 50%;
    transform: rotate(135deg);
    transform-origin: center;
}
.stats .overall-left .top .network-fee-graphic::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: lightgray;
    border-radius: 50%;
    transform: rotate(45deg);
    transform-origin: center;
} */
.stats .overall-left .top .network-fees-title{
    font-weight:700;
    font-size:11px;
}
.stats .overall-left .top .live-price-details-wrap{
    position:absolute;
    bottom:0;
    right:0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    z-index:20;
}
.stats .overall-left .top .live-price-details-wrap .live-callout{
font-size:8px;
padding-right:3px;
}
.stats .overall-left .top .live-price-details-wrap .price-callout{
font-size:16px;
font-weight:600;
}
.stats .overall-left .top .live-price-wedge{
    border-left: 100px solid #0000;
    border-bottom: 40px solid white;
    bottom: 0;
    height: 0;
    position: absolute;
    right: 0;
    width: 0;
    z-index:10;
}
.stats .overall-left .bottom{
    width:100%;
    flex:1;
    padding: 3px;
    box-sizing: border-box;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    background: #f7f7f7;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.stats .overall-left .bottom img{
    align-self:flex-start;
}
.stats .overall-left .bottom .text-wrap{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height:100%;
}
.stats .overall-left .bottom .text-wrap .top-text{
    align-self: flex-start;
    padding: 1px 5px;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-style:italic;
}
.stats .overall-left .bottom .text-wrap .small-text{
    font-size:8px;
    white-space:nowrap;
    text-align: right;
    width: 100%;
}
.stats .overall-right{
    width: 268px;
    box-sizing: border-box;
    height:100%;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    background: #f7f7f7;
    padding: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.stats .overall-right .top-banner{
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height:27px;
}
.stats .overall-right .top-banner .total-title{
    font-size: 12px;
    font-weight: 600;
    padding-right: 5px;
}
.stats .overall-right .top-banner .totals-wrap{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height:22px;
    border-radius:5px;
    position:relative;
    overflow:hidden;
    border: 1px solid #dddddd;
    font-size: 12px;
    font-weight: 700;
}
.stats .overall-right .top-banner .totals-wrap .usd{
    padding:5px;
    background:white;
    border-radius:5px;
    width:50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.stats .overall-right .top-banner .totals-wrap .btc{
    padding:5px;
    border-radius:5px;
    width:80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.stats .overall-right .top-banner .totals-wrap .wedge{
    width:50px;
    height:35px;
    background:white;
    transform:skewY(-60deg);
    position:absolute;
    left:35px;
}
.stats .overall-right .historical-graph-wrap{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width:100%;
    height:107px;
}
.stats .overall-right .historical-graph-wrap .graph{
    flex:2;
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.stats .overall-right .historical-graph-wrap .graph .selection-header{
    width:100%;
    height:20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left:10px;
    box-sizing:border-box;
}
.stats .overall-right .historical-graph-wrap .graph .d3-graph-plot{
    width:100%;
    height:86px;
}
.stats .overall-right .historical-graph-wrap .side-panel{
    width:40px;
}
.stats .overall-right .historical-graph-wrap .graph .selection{
box-sizing:border-box;
padding: 2px 4px;
margin-left: 2px;
font-size: 12px;
font-weight: 600;
color:rgb(130, 130, 130);
}
.stats .overall-right .historical-graph-wrap .graph .selection.x{
    color:black;
}
.stats .overall-right .historical-graph-wrap .graph .selection:hover{
    color:black;
    cursor:pointer;
}

@media (max-width: 768px) {
  .right-column {
    order: -1; /* Move the left column to the top on mobile */
  }
}





.Title10 {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    /* background-image: linear-gradient(to top right, #95bfd0, #2b7fa0); */
    background:#e6f3f3;
    color: #55a37f;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 30px;
    font-weight: 800;
    border-bottom: 2px solid black;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); /* Add box shadow */
    /* text-shadow: 1px 1px 1px black; */
    font-family: Vermina;
    font-size: 28px;
    line-height: 24px;
    padding-top: 5px;
    letter-spacing:1px;
}
.Title11{
    font-size: 24px;
    font-weight: 700;
    color:black;
}
.Title12{
    color:black;
}
.Title13{
    color:black;
}
.Title14 {
    height: 30px;
    font-weight: 800;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size:20px;
    z-index:4;
    /* background-image: linear-gradient(to top right, #95bfd0, #2b7fa0); */
    background:#e6f3f3;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom: 2px solid black;
    color: #55a37f;
    font-family: Vermina;
    font-size: 26px;
    line-height: 24px;
    padding-top: 5px;
    letter-spacing:1px;
    /* text-shadow: 1px 1px 1px black; */
}
.Title15 {
    height:35px;
    width:100%;
    color:black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight:800;
    font-size:27px;
    line-height:35px;
}

.Title16{
    border-bottom:2px solid grey;
    font-weight:700;
    line-height:26px;
}
.Title17{
    margin-top:5px;
    text-align:center
}





/* .Title14 {
    height: 30px;
    color: white;
    font-weight: 700;
    background-image: linear-gradient(to bottom, #334d56, #223a43);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom: 2px solid black;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-shadow: 1px 1px 1px rgb(69, 69, 69);
    font-size:20px;
    border-top:1px solid #e6d9d9;
}
.Title15 {
    height:35px;
    width:100%;
    color:white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight:800;
    font-size:27px;
    line-height:35px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-top:1px solid rgb(208, 208, 208);
    border-right:1px solid black;
    border-left:1px solid black;
    border-bottom:2px solid black;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-image: linear-gradient(to bottom, #5e828f, #2e4e5d);
} */



.moreAddressDetails{
    position:relative;
    width:98%;
    height:98%;
}

.Title80{
    position:absolute;
}
.SETTINGS-MODULE{

}

.SETTINGS-MODULE .module-body{
    box-sizing: border-box;
    padding:10px;
}

.SETTINGS-MODULE .module-body .form-control{
    width:240px;
}
.SETTINGS-MODULE .module-body .form-control input{
    border-radius:5px;
    border:1px solid #909090;
    padding:3px;
}
.SETTINGS-MODULE .module-body .form-control input:focus{
    margin:-1px;
    border:2px solid #2baf3b;
}
.SETTINGS-MODULE .module-body .form-control #org_id{
    background: #ececec;
    border: 1px solid #909090;
    color: #8b8b8b;
    font-size: 12px;
    line-height: 20px;
    padding-left: 10px;
    margin:0px;
}
.SETTINGS-MODULE .module-body .form-control #email{
    background: #ececec;
    border: 1px solid #909090;
    color: #8b8b8b;
    font-size: 12px;
    line-height: 20px;
    padding-left: 10px;
    margin:0px;
}
.SETTINGS-MODULE .module-body #org-save{
    padding:5px;
}
.SETTINGS-MODULE .module-body #profile-save{
    padding:5px;
}

.SETTINGS-MODULE .billing-option-row{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content: flex-start;
}
.SETTINGS-MODULE .billing-option-row .billing-option{
    padding:5px;
    margin-right:6px;
    cursor:pointer;
    transition:.1s;
}
.SETTINGS-MODULE .billing-option-row .billing-option.selected{
    font-weight:600;
    border-bottom: 1px solid rgb(148, 148, 148);
}
.SETTINGS-MODULE .billing-option-row .billing-option.not-selected{
    font-weight:200;
}




.SETTINGS-MODULE .billing-overview {
  padding:10px;
}



.SETTINGS-MODULE .billing-overview .invoice-wrapper {
  margin-bottom:20px;
}





.SETTINGS-MODULE .billing-overview .invoice-wrapper .button-row {
  /* Styles for .button-row */
}

.SETTINGS-MODULE .billing-overview .invoice-wrapper .button-row button {
  cursor:pointer;
}

.SETTINGS-MODULE .billing-overview .other-billing-actions{
    margin-bottom:20px;
    max-width:420px;
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    justify-content: center;
}
.SETTINGS-MODULE .billing-overview .other-billing-actions .other-payment-methods{
    height:40px;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content: center;
}
.SETTINGS-MODULE .billing-overview .other-billing-actions .other-billing-history{
    height:40px;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content: center;
}


.SETTINGS-MODULE .billing-overview .invoice-memo {
  max-width:480px;
}




.SETTINGS-MODULE .billing-overview .billing-graphic-wrapper {
  /* Styles for .billing-graphic-wrapper */
}


.update-default-method-wrap{
    height: 100vh;
    width: 100vw;
    background:rgb(100, 100, 100);
    opacity:80%;
    position:fixed;
    top:0;
    left:0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.update-default-method-wrap .inner-content{
    width: 500px;
    height: 220px;
    border: 1px solid rgb(190, 190, 190);
    background: white;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 10px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5); /* Add box shadow here */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.update-default-method-wrap .inner-content .details-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom:10px;
}
.update-default-method-wrap .inner-content button{
    cursor:pointer;
    margin-bottom:5px;
}


.remove-payment-method-wrap{
    height: 100vh;
    width: 100vw;
    background:rgb(100, 100, 100);
    opacity:80%;
    position:fixed;
    top:0;
    left:0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.remove-payment-method-wrap .inner-content{
    width: 500px;
    height: 220px;
    border: 1px solid rgb(190, 190, 190);
    background: white;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 10px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5); /* Add box shadow here */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.remove-payment-method-wrap .inner-content .details-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom:10px;
}
.remove-payment-method-wrap .inner-content button{
    margin-bottom:5px;
    cursor:pointer;
}

.add-payment-method-wrap{
    height: 100vh;
width: 100vw;
background:rgb(100, 100, 100);
opacity:80%;
position:fixed;
top:0;
left:0;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}
.add-payment-method-wrap .inner-content{
    width: 500px;
    height: 220px;
    border: 1px solid rgb(190, 190, 190);
    background: white;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 10px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5); /* Add box shadow here */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.add-payment-method-wrap .inner-content form{
    min-width:330px;
    max-width:400px;
    width:100%;
    height:200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.add-payment-method-wrap .inner-content form .card-element-container{
    background-color: #fbfbfb;
    color:#003b0e;
    border: 1px solid #e4e4e4;
    width:100%;
    padding:12px;
    border-radius:4px;
    margin-bottom:10px;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #999999;
    position:relative;
}
.add-payment-method-wrap .inner-content form .card-element-container.error{
    background-color: #ffa8a8;
    color:#003b0e;
    border: 1px solid #eb8357;
    width:100%;
    padding:12px;
    border-radius:4px;
    margin-bottom:10px;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #ba6363;
    transition:.5s;
}
.add-payment-method-wrap .inner-content form .card-element-container .error-text{
    position:absolute;
    top:-50px;
    color:#f86527;
}

.add-payment-method-wrap .inner-content form .card-element-container.active{
    background-color: #adadad;
}
.add-payment-method-wrap .inner-content .StripeElement{
    width:100%;
}
.add-payment-method-wrap .inner-content form .button-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width:100%;
}
.add-payment-method-wrap .inner-content form .button-row .stripe-promotional-wrap{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.add-payment-method-wrap .inner-content form .button-row .stripe-promotional-wrap img{
    max-height:22px;
}
.add-payment-method-wrap .inner-content form button{
    padding:5px;
    cursor:pointer;
}




.billing-modal-content{
    width: 500px;
    height: 220px;
    border: 1px solid rgb(190, 190, 190);
    background: white;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 10px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5); /* Add box shadow here */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.billing-modal-wrap{
height: 100vh;
width: 100vw;
background:rgb(100, 100, 100);
opacity:80%;
position:fixed;
top:0;
left:0;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}

.billing-modal-content .title-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
.billing-modal-content .title-row .striple-logo-wrap{
    width:100px;
}
.billing-modal-content .title-row .striple-logo-wrap img{
    width:100%;
}

.loading-circle{
    height:16px;
    width:16px;
    border:solid 2px #8822aa;
	border-radius: 50%;
	border-right-color: transparent;
	border-bottom-color: transparent;
    animation-name:             rotate; 
    animation-duration:         1.0s; 
    animation-iteration-count:  infinite;
    animation-timing-function: linear; 
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to { 
        transform: rotate(360deg);
    }
}






.module-body .billing-history-wrap{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.module-body .billing-history-wrap .billing-history-list{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:450px;
}
.module-body .billing-history-wrap .billing-history-list .header-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width:100%;
}
.module-body .billing-history-wrap .billing-history-list .individual-billing-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width:100%;
}








.module-body .payment-methods{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.module-body .payment-methods .current-methods-wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom:10px;
}
.module-body .payment-methods .individual-method{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding:5px;
    border: 1px solid grey;
    border-radius:4px;
    position:relative;
}
.module-body .payment-methods .individual-method .delete-method-icon{
    position: absolute;
    top: 2px;
    right: 2px;
    cursor:pointer;
}
.module-body .payment-methods .left-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:5px;
    margin-right:10px;
}
.module-body .payment-methods .right-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:5px;
}

.module-body .payment-methods .additional-method-wrap{
    padding:5px;
    border:1px solid grey;
    border-radius:2px;
    width:250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor:pointer;
}
.module-body .payment-methods .additional-method-wrap:hover{
    border:2px solid grey;
    margin-top:-1px;
}
.module-body .payment-methods .additional-method-wrap:active{
    border:1px solid rgb(0, 0, 0);
    margin-top:0px;
}







.Title21{
    font-weight:700;
    font-size:20px;
}
.Title22{
    font-weight:500;
    font-size:18px;
    margin-top:15px;
}
.Title23{
    margin-top:2px;
    margin-bottom:6px;
    font-weight:400;
    font-size:12px;
}

.Title41{
    font-size:18px;
}
.Title42 {
 font-weight:700;
 font-size:24px;
 margin-bottom:10px;
  }
.Title43 {
    font-weight:500;
    font-size:16px;
  }
  
.Title44 {
    font-weight:600;
    font-size:28px;
    line-height:30px;
  }
  .Title44.hidden {
    font-weight:600;
    font-size:28px;
    line-height:30px;
    opacity:0;
  }
.Title45 {
    font-weight:300;
    font-size:16px;
    margin-bottom:5px;
  }
.Title46 {
    font-weight:300;
    font-size:16px;
    line-height:20px;
  }
  .Title47{
    cursor:pointer;
  }
  .Title48{
    font-size: 26px;
    font-weight: 200;
    margin-bottom: 8px;
  }
.Title57{
    text-align: right;
    align-self: flex-end;
    font-size: 12px;
    font-weight: 600;
    padding:5px;
    cursor:pointer;
    color: #cf1e1e;
    margin-top:-4px;
}
.Title57:hover{
    color: #a90404;
}
.Title62{
    margin-bottom:5px;
}
.Title62.error{
    margin-bottom:5px;
    color:red;
}
.Title64{
    font-size: 10px;
    font-weight: 600;
    cursor:pointer;
}
.Title64:hover{
    font-weight: 800;
}
/* .Title65{
    padding: 4px;
    width: 120px;
}
.Title67{
    padding: 4px;
    width: 50px;
}
.Title68{
    padding: 4px;
    width: 50px;
}

.Title75{
    padding: 4px;
    width: 120px;
}
.Title77{
    padding: 4px;
    width: 50px;
} */
.console-wrap {
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.console-header{
    height:40px;
    width:100%;
    background: linear-gradient(to bottom right, #0a172c, #153156);
    box-sizing: border-box;
    padding:10px;
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: space-between;
}
.console-header .options{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor:pointer;
  font-family: Cabolafe, Arial, serif;
  letter-spacing:1px;
}
.console-header .options .spacer{
width:40px;
}

.sub-panel-wrap{
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
    width:100%;
    height:95vh;
}
.side-panel {
  width: 200px;
  height: 100%;
  background-color: #f3f3f3;
  padding: 10px;
  box-sizing: border-box;
  border-right:1px solid rgb(232, 232, 232);
}

.panel-selector{
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: flex-start;
    width:100%;
    padding-left:5px;
    padding-top:5px;
    padding-bottom:5px;
    font-size:28px;
    line-height:20px;
    box-sizing: border-box;
    border-radius:5px;
    font-family: 'Vermina';
    font-weight: 600;
    color: #464646;
    letter-spacing: .4px;
}

.panel-selector:hover{
    cursor:pointer;
    background-color: #e9e9e9;
}

.settings-dropdown{
    width:100%;
    text-align:left;
    margin-top: 5px;
}
.settings-dropdown .dropdown-title{
    margin-bottom: 2px;
    height: 20px;
    padding: 3px;
    padding-left:25px;
    display: block;
    border-radius:5px;
    cursor:pointer;
}
.settings-dropdown .dropdown-title:hover{
    background:rgb(214, 214, 214);
}
.settings-dropdown .dropdown-title.selected{
    background: #e6f3f3;
    color:#55a37f;
}

.logo-header{

}
.wallet {
  /* Styles for wallet */
}

.billing {
  /* Styles for billing */
}

.api-keys {
  /* Styles for api */
}

.documentation{

}
.graphic{
    margin-right: 7px;
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
    width:68px;
}
.graphic img{
  max-width:100%;
}

.main-panel {
  flex: 1;
  height:100%;
  /* Styles for main-panel */
  
}

.Title1{
    color:white;
    font-size:24px;
    font-weight:600;
    font-family: Moodern, Arial, serif;
    text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
    0px 8px 13px rgba(0,0,0,0.1),
    0px 18px 23px rgba(0,0,0,0.1);
}
.Text1{
    color:white;
    font-weight:800;
}
.Title100{
  line-height: 10px;
  margin-top: 8px;
}
.register-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    color: #333;
    background-color: #fdfdfd;
}

.register-wrap .header {
    margin: 0 0 20px 0;
    color: #5D3FD3;
}

.register-wrap .button {
    padding: 10px 20px;
    font-size: 18px;
    color: white;
    background: #5D3FD3;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
}

.register-wrap .passwordBox {
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    color: #333;
    background: #D3D3D3;
}

.register-wrap .error {
    color: red;
    margin-bottom: 15px;
}

.register-wrap .create-account-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
    height: 100vh;
    background-color: #fdfdfd;
    width:300px;
}

.register-wrap #createRegisterForm {
    padding: 20px;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 300px;
}

.register-wrap .or-row{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction:row;
    width:100%;
    margin-top:30px;
    font-weight:300;
    color:rgb(79, 79, 79);
}
.register-wrap .or-row .or-line{
    width:105px;
    height:1px;
    background:rgb(158, 158, 158);
}
.register-wrap .google-wrap{
    margin-top:25px;
    width:205px;
    height:40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
}
.register-wrap h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.register-wrap .input-group {
    margin-bottom: 15px;
    width:330px;
}


.register-wrap .input-control .input-label{
    position:absolute;
    top:23px;
    left:17px;
    transition:.2s;
    font-size:18px;
    line-height:30px;
    padding:0 8px;
    background:#fdfdfd;
    color:#9a9a9a;
    border-radius:10px;
}

.register-wrap .input-control .input-label-focused{
    position:absolute;
    top:0px;
    left:10px;
    transition:.2s;
    padding:0 8px;
    background:#fdfdfd;
    color:#55a37f;
    border-radius:10px;
}
.register-wrap .input-group input {
    width: 100%;
    padding: 10px;
    border: 2px solid #9a9a9a;
    border-radius: 5px;
    height:50px;
    box-sizing: border-box;
    background:#ffffff;
    transition:.2s;
    font-size:18px;
    font-weight: 200;
    color: rgb(96 96 96);
}
.register-wrap .input-group input:focus {
    outline:none;
    border: 2px solid #55a37f;
    transition:.2s;
}
.register-wrap .input-control p{
    margin:0;
    height:20px;
}
.register-wrap button {
    width: 100%;
    padding: 10px;
    background-color: #5c6bc0;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.register-wrap button:hover {
    background-color: #3949ab;
}
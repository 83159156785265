.form-control {
  margin: 0 0;
  font-family: brandon-grotesque, sans-serif;
}

.form-control label,
.form-control input,
.form-control textarea {
  display: block;
}

.form-control label {
  font-weight: 300;
  font-size:20px;
  color:rgb(46, 46, 46);
}

.form-control input,
.form-control textarea {
  width: 100%;
  font: inherit;
  font-size:18px;
  border: 1px solid #ccc;
  background: #ffffff;
  padding: 0.15rem 0.35rem;
  font-weight:400 !important;
}

.form-control input:focus,
.form-control textarea:focus {
  outline: none;
  background: #ffffff;
  border-color: #510077;
}

.form-control--invalid label,
.form-control--invalid p {
  color: #f84b07;
  margin-block-start: 0;
  margin-block-end: 5px;
}

.form-control--invalid p{
  font-size: 13px;
}

.form-control--invalid input,
.form-control--invalid textarea {
  border-color: #f84b07;
}

.form-control p {
  height:20px;
  margin-block-start:0px;
  margin-block-end:5px;
}
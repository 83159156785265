.home-container {
    font-family: 'Arial', sans-serif;
    color: white;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    position:relative;
  }
  
  .home-header {
    padding: 1px 20px;
    background: #000000ab;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 10;
    width: 100%;
    box-sizing: border-box;
  }
  
  .navigation ul {
    list-style: none;
    display: flex;
    justify-content: flex-end;
  }
  
  .navigation li {
    margin-left: 20px;
    font-family: Cabolafe, Arial, serif;
    letter-spacing:1px;
  }
  
  .navigation a {
    text-decoration: none;
    color: white;
    font-size: 16px;
  }
  
.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-image: url('../../../public/img/block_backg1.png');
  background-size: cover;
  background-position:50% 50%;
  position:relative;
}
.main-content .svg-wrapper{
  position:absolute;
  width:100%;
  height:100%;
  top:0px;
  left:0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-content .svg-wrapper img{
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
}

  
  .main-title {
    font-size: 7em;
    line-height:50px;
    margin-bottom: 0;
    font-family: Moodern, Arial, serif;
    text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
    0px 8px 13px rgba(0,0,0,0.1),
    0px 18px 23px rgba(0,0,0,0.1);
    z-index:10;
  }
  
  .main-description {
    font-size: 3em;
    margin-bottom: 0;
    margin-top:0;
    max-width: 600px;
    font-family:Bogimber, Arial, serif;
    text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
    0px 8px 13px rgba(0,0,0,0.1),
    0px 18px 23px rgba(0,0,0,0.1);
    padding:5px 20px;
    background: rgba(0, 0, 0, 0.359);
    border-radius:3px;
  }
  
  .main-button {
    padding: 15px 30px;
    font-size: 1em;
    color: #000c17;
    background-color: #00aaff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .main-button:hover {
    background-color: #0088cc;
  }
  
.api-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f7f7f7; /* Light grey background */
}

.api-container .api-header {
    color: #5c6bc0; /* Stylish color for the header */
    font-size: 2em;
    margin-bottom: 20px;
}

.api-container .generate-button {
    padding: 10px 20px;
    background-color: #5c6bc0; /* Matching the header */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
}

.api-container .generate-button:hover {
    background-color: #3949ab; /* Slightly darker on hover */
}

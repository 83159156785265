.doc-wrap{
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
    width:100vw;
}

.doc-wrap .content-wrap{
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width:100%;
}
.doc-wrap .content-wrap .title{
    border-bottom: 1px solid #e8e8e8;
    box-sizing: border-box;
    font-weight: 700;
    min-height: 60px;
    padding: 10px 10px 0;
    width: 100%;
}

.doc-wrap .content-wrap .api-doc-panel{
    border-bottom: 1px solid #e8e8e8;
    box-sizing: border-box;
    padding: 10px 10px 0;
    width: 100%;
    display: flex;
    flex-direction:row;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 25px;
}
.doc-wrap .content-wrap .api-doc-panel .left{
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    padding-left:40px;
    padding-right:20px;
    padding-top:40px;
    padding-bottom:40px;
    max-width:350px;
    position:relative;
}
.doc-wrap .content-wrap .api-doc-panel .right{
    display: flex;
    flex-direction:column;
    align-items: flex-end;
    justify-content: center;
    padding-left:40px;
    padding-right:20px;
    padding-top:40px;
    padding-bottom:40px;
    max-width:350px;
    position:relative;
}


.Title70{
    font-size:24px;
}
.Title71{
    position: absolute;
    top: 2px;
    left: 38px;
    font-weight: 600;
    font-size:28px;
}
.Title72{
    font-size:22px;
    width:100%;
    font-weight:600px;
    line-height:24px;
    margin-bottom:3px;
}
.doc-wrap .content-wrap .api-doc-panel .paragraph{
    text-align: justify;
    font-size: 17px;
    font-weight:100;
    width:100%;
}
.doc-wrap .content-wrap .api-doc-panel .http-status-code{
    width:100%;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    border-radius:5px;
    border:1px solid rgb(204, 204, 204);
}
.doc-wrap .content-wrap .api-doc-panel .http-status-code .status-title{
width:100%;
padding:5px;
box-sizing: border-box;
background: #e0e0e0;
}
.doc-wrap .content-wrap .api-doc-panel .http-status-code .table-wrap{
    width:100%;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    background: #fcfcfc;
}

.doc-wrap .content-wrap .api-doc-panel .http-status-code .status-row{
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: space-between;
    padding:5px;
    width:100%;
    box-sizing: border-box;
    line-height:16px;
    font-size:14px;
    font-weight:100;
}
.doc-wrap .content-wrap .api-doc-panel .http-status-code .status-row .status{
    flex:1;
}
.doc-wrap .content-wrap .api-doc-panel .http-status-code .status-row .short{
    flex:2;
    font-weight:600;
}
.doc-wrap .content-wrap .api-doc-panel .http-status-code .status-row .long{
    flex:4;
}



.doc-wrap .content-wrap .api-doc-panel .break{
    height:25px;
}

.doc-wrap .content-wrap .api-doc-panel code{
    padding:5px;
    background:black;
    color:white;
    border-radius:5px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 300;
}

.doc-wrap .content-wrap .api-doc-panel .header-wrap{
    width:400px;
    border-radius:5px;
    padding:5px;
    box-sizing: border-box;
}

.doc-wrap .content-wrap .api-doc-panel .header-wrap .row{
    margin-bottom:10px;
}
.doc-wrap .content-wrap .api-doc-panel .header-wrap .row .toptext{
    border-bottom:1px solid grey;
}
.doc-wrap .content-wrap .api-doc-panel .header-wrap .row .subtext{
    font-size: 13px;
    font-weight: 100;
}



#public-header-key-span{
    color:blue;
    font-size: 20px;
    line-height: 25px;
    font-weight: 600;
}


.doc-wrap .content-wrap .api-doc-panel .data-param-wrap{
    width:400px;
    border-radius:5px;
    padding:5px;
    box-sizing: border-box;
}
.doc-wrap .content-wrap .api-doc-panel .data-param-wrap .row{
    margin-bottom:10px;
}
.doc-wrap .content-wrap .api-doc-panel .data-param-wrap .row .toptext{
    border-bottom:1px solid grey;
}
.doc-wrap .content-wrap .api-doc-panel .data-param-wrap .row .subtext{
    font-size: 13px;
    font-weight: 100;
}
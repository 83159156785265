.Post-Checkout-Loading-Wrap{
    height:100vh;
    width:100vw;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    background:#212121;
}
.Post-Checkout-Loading-Wrap .comment-block{
    height:100px;
    margin-bottom:20px;
    color:white;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
}
.Post-Checkout-Loading-Wrap .loader{
    position: relative;
    width: 96px;
    height: 96px;
    transform: rotate(45deg);
}
.Post-Checkout-Loading-Wrap .loader-square{
    position: absolute;
    top: 0;
    left: 0;
    width: 28px;
    height: 28px;
    margin: 2px;
    border-radius: 0px;
    background: white;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    animation: square-animation 10s ease-in-out infinite both;
}

.Post-Checkout-Loading-Wrap .loader-square:nth-of-type(0) {
  animation-delay: 0s;
}

.Post-Checkout-Loading-Wrap .loader-square:nth-of-type(1) {
  animation-delay: -1.4285714286s;
}

.Post-Checkout-Loading-Wrap .loader-square:nth-of-type(2) {
  animation-delay: -2.8571428571s;
}

.Post-Checkout-Loading-Wrap .loader-square:nth-of-type(3) {
  animation-delay: -4.2857142857s;
}

.Post-Checkout-Loading-Wrap .loader-square:nth-of-type(4) {
  animation-delay: -5.7142857143s;
}

.Post-Checkout-Loading-Wrap .loader-square:nth-of-type(5) {
  animation-delay: -7.1428571429s;
}

.Post-Checkout-Loading-Wrap .loader-square:nth-of-type(6) {
  animation-delay: -8.5714285714s;
}

.Post-Checkout-Loading-Wrap .loader-square:nth-of-type(7) {
  animation-delay: -10s;
}

   @keyframes square-animation {
    0% {
     left: 0;
     top: 0;
    }
   
    10.5% {
     left: 0;
     top: 0;
    }
   
    12.5% {
     left: 32px;
     top: 0;
    }
   
    23% {
     left: 32px;
     top: 0;
    }
   
    25% {
     left: 64px;
     top: 0;
    }
   
    35.5% {
     left: 64px;
     top: 0;
    }
   
    37.5% {
     left: 64px;
     top: 32px;
    }
   
    48% {
     left: 64px;
     top: 32px;
    }
   
    50% {
     left: 32px;
     top: 32px;
    }
   
    60.5% {
     left: 32px;
     top: 32px;
    }
   
    62.5% {
     left: 32px;
     top: 64px;
    }
   
    73% {
     left: 32px;
     top: 64px;
    }
   
    75% {
     left: 0;
     top: 64px;
    }
   
    85.5% {
     left: 0;
     top: 64px;
    }
   
    87.5% {
     left: 0;
     top: 32px;
    }
   
    98% {
     left: 0;
     top: 32px;
    }
   
    100% {
     left: 0;
     top: 0;
    }
   }




   .Title50{
    font-size:38px;
    font-weight:200;
    opacity:0;
    animation:title-appear1 1.5s ease forwards;
   }
   .Title51{
    font-size:20px;
    font-weight:200;
    opacity:0;
    animation:title-appear2 .5s ease forwards;
    animation-delay:1.4s;
   }

   @keyframes title-appear1{
        0%{
            opacity:0;
            transform:translateY(60px);
        }
        40%{
            opacity:1;
            transform: translateY(0px);
        }
        60%{
            opacity:1;
            transform: translateY(0px);
        }
        100%{
            opacity:0;
            transform: translateY(-30px);
        }
   }
   @keyframes title-appear2{
        0%{
            opacity:0;
            transform: translateY(0px);
        }
        100%{
            opacity:1;
            transform: translateY(-30px);
        }
   }
.input-control{
    position:relative;
    padding-top:14px;
}

.input-control .input-label{
    position:absolute;
    top:15px;
    left:17px;
    transition:.2s;
}

.input-control .input-label-focused{
    position:absolute;
    top:0px;
    left:10px;
    transition:.2s;
}
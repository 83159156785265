.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#modal {
  position:relative;
  z-index:5;
}



.Title2 {
  font-size: 24px;
  font-family: Cabolafe, Arial, serif;
}

.Title4{
  font-weight:700;
}
.Title5{
  font-weight:700;
  font-size:20px;
}
.Title6{
  font-weight:700;
  font-size:16px;
  margin-right:5px;
}
.Title7{
  font-weight:300;
  font-size:14px;
}




@font-face {
  font-family: Moodern;
  src: local('Moodern'), url(./fonts/MOODERN.otf) format('opentype');
}

@font-face {
  font-family: Bogimber;
  src: local('Bogimber'), url(./fonts/Bogimber.otf) format('opentype');
}

@font-face {
  font-family: Cabolafe;
  src: local('Cabolafe'), url(./fonts/Cabolafe.otf) format('opentype');
}

@font-face {
  font-family: Vermina;
  src: local('Vermina'), url(./fonts/Vermina.ttf) format('truetype');
}

@font-face {
  font-family: Oleragie;
  src: local('Oleragie'), url(./fonts/Oleragie.otf) format('opentype');
}


.API-MODULE {
  width:100%;
}

.API-MODULE .title {
  font-weight: bold;
  height:60px;
  border-bottom:1px solid rgb(232, 232, 232);
  width:100%;
  box-sizing: border-box;
  padding:10px;
}

.API-MODULE 

.API-MODULE .module-body {
  box-sizing: border-box;
  padding:10px;
}

.API-MODULE .body-text1 {
  margin-bottom: 12px;
  max-width:700px;
}

.API-MODULE .apiKey-table {
  /* Add styles for the apiKey-table here */
  width:700px;
}

.API-MODULE .apiKey-table .table-title-row{
    border-bottom:1px solid rgb(232, 232, 232);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .API-MODULE .apiKey-table .table-body{
    min-height:20px;
  }
  .API-MODULE .apiKey-table .table-body-row{
    border-bottom:1px solid rgb(232, 232, 232);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height:26px;
  }

  .API-MODULE .apiKey-table .table-body-row .load-glyph{
    width:90%;
    height:20px;
    border-radius:4px;

    transition: 1s;
    overflow:hidden;
  }

.API-MODULE .apiKey-table .table-body-row .load-glyph .load-background {
  width: 500px;
  height: 105%;
  /* background-image:linear-gradient(to right, rgb(237, 237, 237), rgb(175, 175, 175));
  animation: colorShiftLoadBar .5s ease infinite alternate; */
}


  .API-MODULE .apiKey-table .table-body-row .delete-wrap{
    width:20px;
    height:20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border:1px solid red;
    cursor:pointer;
  }

.API-MODULE .apiKey-table .table-col1 {
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
  .API-MODULE .apiKey-table .table-col2{
    width:110px;
    text-align:right;
  }
  .API-MODULE .apiKey-table .table-col3{
    width:150px;
    text-align:right;
  }
  .API-MODULE .apiKey-table .table-col4{
    width:150px;
    text-align:right;
  }
  .API-MODULE .apiKey-table .table-col5{
    width:130px;
  }

  .API-MODULE .table-additions{
    margin-top:30px;
  }

  .API-MODULE .table-additions button{
    padding:5px;
  }
  .API-MODULE .table-additions button:hover{
    cursor:pointer;
  }


.reg-modal-content{
    width: 500px;
    height: 220px;
    border: 1px solid rgb(190, 190, 190);
    background: white;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 10px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5); /* Add box shadow here */
}
.reg-modal-wrap{
height: 100vh;
width: 100vw;
background:rgb(100, 100, 100);
opacity:80%;
position:fixed;
top:0;
left:0;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}
.reg-modal-content .name-optional{
    width:90%;
    margin:auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top:20px;
}
.reg-modal-content .form-control{
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;;
}
.reg-modal-content .form-control label{
    width:100%;
    padding-left:5%;
}
.reg-modal-content .form-control input{
    width:90%;
    border-radius:5px;
    border:1px solid #2baf3b;
}
.reg-modal-content .button-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-right:5%;
}
.reg-modal-content .button-row button{
    padding:5px;
}
.reg-modal-content .button-row .cancel{
    margin-right:5px;
}
.reg-modal-content .button-row .create{
    min-width:160px;
}

.reg-modal-content .loading-circle{
    height:12px;
    width:12px;
    border:solid 1px #8822aa;
	border-radius: 50%;
	border-right-color: transparent;
	border-bottom-color: transparent;
    animation-name:             rotate; 
    animation-duration:         1.0s; 
    animation-iteration-count:  infinite;
    animation-timing-function: linear; 
}
.reg-modal-content .secretkey-input-wrap{
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
}
.reg-modal-content .secretkey-input-wrap input{
    width:80%;
    height: 25px;
}
.reg-modal-content .secretkey-input-wrap button{
    width:15%;
    padding:5px;
    height:31px;
    margin-left:5px;
}

.reg-modal-content .revoke-input{
    margin-top: 10px;
    margin-bottom: 10px;
    width:50%;
    height: 25px;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to { 
        transform: rotate(360deg);
    }
}

@keyframes colorShiftLoadBar {
    from {
        transform: translateX(0px);
    }
    to { 
        transform: translateX(-350px);
    }
}



.Title52.error{
  color:red;
}
.Title8.error{
  color:red;
}
.Title9.error{
  color:red;
}